<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import awardCategorieCard from "../../../components/widgets/card/awardCategorieCard.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
// import newHeader from "../../../components/newHeader.vue";
// import chartCandidatAwardVue from "../../../components/widgets/chartComponent/chartCandidatAward.vue";
import statAward from "../../../components/widgets/statAward.vue";
/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Projects Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    awardCategorieCard,
    loaderProcessVue,
    // chartCandidatAwardVue,
    statAward,
    // newHeader,
  },
  data() {
    return {
      transactionModal: false,
      titre: "",
      isAuthError: false,
      showConfirmNameAward: false,
      confirmNameAward: "",
      authError: "",
      progress: 0,
      searchQuery: "",
      allCandidate: "",
      amountPayment: "",
      transactionCount: "",
      isDisabled: true,
      process: false,
      awardDetails: [],
      shartTitle: "Evolutions des votes",
      serie: [],
      categorieStats: [],
      award: {
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        hasCategorie: false,
        isMultiVote: false,
        isFree: false,
        isMultiSameVote: false,
        categories: [],
        votePrice: "",
        isPublish: null,
      },
      voteCountInCategorie: 0,
      candidateUser: {
        name: "",
        description: "",
        idCategorie: [],
      },
      image: "",
      updateModal: false,
      addModalCandidate: false,
      addModal: false,
      time: "",
      awardCategorie: {
        name: "",
        votePrice: "",
        description: "",
      },
      title: "azertre",
      items: [],
    };
  },
  methods: {
    toggleInput() {
      this.isDisabled = !this.isDisabled;
    },

    showAddModal() {
      this.addModal = true;
    },

    showConfirmNameAwardModal() {
      this.showConfirmNameAward = true;
    },

    showTransactionModal() {
      this.transactionModal = true;
    },

    showUpdateModal() {
      this.updateModal = true;
    },

    showAddModalCandidate() {
      this.addModalCandidate = true;
    },

    checkTrueOrFalse(value) {
      if (value === true) {
        return "Oui";
      } else {
        return "Non";
      }
    },

    imgPortraitCandidate(event) {
      this.candidateImgPortrait = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgPaysageCandidate(event) {
      this.candidateImgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    imageFileSelected(event) {
      this.imageSelectFile = event.target.files[0];
      console.log(this.imageSelectFile);
    },

    imagePFileSelected(event) {
      this.imagePSelectFile = event.target.files[0];
      console.log(this.imagePSelectFile);
    },

    videoSelect(event) {
      this.video = event.target.files[0];
      console.log(this.video);
    },

    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    updateAward() {
      this.process = true;
      this.updateModal = false;
      this.titre = "Modification de la compétition";
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      if (this.imgSelect) {
        this.process = true;
        this.updateModal = false;
        Api.putFormData(
          `/voting/rest/awards/img-portrait/${this.$route.params.id}`,
          {
            imageportrait: this.imgSelect,
          },
          onUploadProgress
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Compétition Modifiée avec succès", "success");
            console.log(response);
            // location.reload()
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }

      if (this.imgPaysage) {
        Api.putFormData(
          `/voting/rest/awards/img-paysage/${this.$route.params.id}`,
          {
            imagepaysage: this.imgPaysage,
          },
          onUploadProgress
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Compétition Modifiée avec succès", "success");
            console.log(response);
            // location.reload()
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }

      Api.putFormData(
        `/voting/rest/awards/${this.$route.params.id}`,
        {
          description: this.award.description,
          endDate: new Date(this.award.endDate),
          free: this.award.isFree,
          hasCategorie: this.award.hasCategorie,
          multiSameVote: this.award.isMultiSameVote,
          multiVote: this.award.isMultiVote,
          name: this.award.name,
          startDate: new Date(this.award.startDate),
          vote_price: this.award.votePrice,
        },
        onUploadProgress
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Compétition Modifiée avec succès", "success");
          console.log(response);
          // location.reload()
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    createCandidate() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      this.process = true;
      this.addModalCandidate = false;

      this.titre = "Création d'un candidat";
      Api.postFormData(
        `/voting/rest/candidat/create-candidat?categories=${this.candidateUser.idCategorie}&description=${this.candidateUser.description}&name=${this.candidateUser.name}`,
        {
          imagepaysage: this.candidateImgPaysage,
          imageportrait: this.candidateImgPortrait,
        },
        onUploadProgress
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Candidat créé avec succès", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    createAwardCategorie() {
      console.log(this.awardCategorie);

      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      if (
        !this.awardCategorie.name ||
        !this.imagePSelectFile ||
        !this.imageSelectFile
      ) {
        this.isAuthError = true;
        this.authError = "Veuillez remplir tous les champs";
      } else {
        this.process = true;
        this.addModal = false;

        this.titre = "Création de catégories";
        Api.postFormData(
          `/voting/rest/categorie/create-categorie?name=${this.awardCategorie.name}&description=${this.awardCategorie.description}&awards=${this.award.name}&vote_price=${this.awardCategorie.votePrice}`,
          {
            imagepaysage: this.imagePSelectFile,
            imageportrait: this.imageSelectFile,
            videocouverture: this.video,
          },
          onUploadProgress
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Catégorie ajoutée", "success");
            console.log(response);
            // this.addCategorieInAward(this.$route.params.id, response.data.content.id)
            location.reload();
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }
    },

    creerMinuteur(dateDebut, dateFin) {
      const differenceEnMillisecondes = dateFin - dateDebut;
      const differenceEnSecondes = Math.floor(differenceEnMillisecondes / 1000);

      let tempsRestant = differenceEnSecondes;
      let minuteur;

      const afficherTempsRestant = (temps) => {
        const jours = Math.floor(temps / (3600 * 24));
        temps %= 3600 * 24;
        const heures = Math.floor(temps / 3600);
        temps %= 3600;
        const minutes = Math.floor(temps / 60);
        const secondes = temps % 60;

        const tempsRestantFormat =
          jours + "j " + heures + "h " + minutes + "m " + secondes + "s";

        // console.log(`Temps restant : ${tempsRestantFormat}`);
        this.time = tempsRestantFormat;
      };

      const demarrerMinuteur = () => {
        minuteur = setInterval(() => {
          if (tempsRestant > 0) {
            tempsRestant--;
            afficherTempsRestant(tempsRestant);
          } else {
            clearInterval(minuteur);
            this.time = "00j 00h 00m 00s";
            console.log("Minuteur terminé !");
          }
        }, 1000); // Interval d'une seconde (1000 ms)
      };

      // Pour démarrer le minuteur
      demarrerMinuteur();
    },

    getCategorieInAward(awardName) {
      Api.get(`/voting/rest/categorie/awards-cat?awards=${awardName}`)
        .then((res) => {
          this.process = false;
          var categorieList = res.data.content;
          console.log(this.award.categories);

          categorieList.forEach((item) => {
            Api.get(`/voting/rest/vote/all-vote-categorie/${item.id}`)
              .then((res) => {
                this.process = false;
                this.voteCountInCategorie =
                  this.voteCountInCategorie + res.data.content.length;
                console.log(this.voteCountInCategorie);

                this.award.categories.push({
                  categorie: item,
                  voteNumber: this.voteCountInCategorie,
                });
              })
              .catch((error) => {
                this.process = false;
                console.log(error);
                Erreur.gestionErreur(error.message);
              });
          });

          this.award.categories.sort((a, b) => b.voteNumber - a.voteNumber);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    getAllCandidateInAward() {
      Api.get(
        `/voting/rest/candidat/all-candidat-in-awards/${this.$route.params.id}`
      )
        .then((res) => {
          this.process = false;
          this.allCandidate = res.data.content;
          console.log(this.allCandidate);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    getAmountPaymentInAward() {
      Api.get(`/voting/rest/payment/all-amount/${this.$route.params.id}`)
        .then((res) => {
          this.process = false;
          this.amountPayment = res.data.content;
          console.log(this.amountPayment);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    getAllTransactionInAward() {
      Api.get(`/voting/rest/payment/all-payment-size/${this.$route.params.id}`)
        .then((res) => {
          this.process = false;
          this.transactionCount = res.data.content;
          console.log(this.transactionCount);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    confirm() {
      Swal.fire({
        title: "Souhaitez-vous publier cette compétition ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          this.publishAward();
        }
      });
    },

    confirmUnPublishAward() {
      Swal.fire({
        title: "Souhaitez-vous dépublier cette compétition ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          this.showConfirmNameAwardModal();
        }
      });
    },

    confirmUnpublishNameAward() {
      if (this.confirmNameAward != this.award.name) {
        Swal.fire("Erreur!", "Compétition introuvable", "error");
      } else {
        this.showConfirmNameAward = false;
        this.unPublishAward();
      }
    },

    publishAward() {
      Api.put(`/voting/rest/awards/publish-awards/${this.$route.params.id}`)
        .then(function (response) {
          Swal.fire("Success!", "Award publié", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    unPublishAward() {
      Api.put(`/voting/rest/awards/unpublish-awards/${this.$route.params.id}`)
        .then(function (response) {
          Swal.fire("Success!", "Award retiré en ligne", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    getDataAward() {
      this.titre = "Chargement des compétitions";
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      Api.get(`/voting/rest/awards/${this.$route.params.id}`, {
        onUploadProgress,
      })
        .then((res) => {
          this.process = false;
          this.awardDetails = res.data.content;
          console.log(this.awardDetails);
          this.image = this.awardDetails.imagesMap.imagepaysage.url;

          this.award.description = this.awardDetails.description;
          this.award.name = this.awardDetails.name;
          this.award.startDate = this.awardDetails.startDate;
          this.award.endDate = this.awardDetails.endDate;
          this.award.isFree = this.awardDetails.free;
          this.award.isMultiSameVote = this.awardDetails.multiSameVote;
          this.award.isMultiVote = this.awardDetails.multiVote;
          this.award.hasCategorie = this.awardDetails.hasCategorie;
          this.award.votePrice = this.awardDetails.vote_price;
          this.award.isPublish = this.awardDetails.isPublished;

          this.items.push(
            {
              text: "Awards",
              href: "/award/awardList",
            },
            {
              text: this.awardDetails.name,
              active: true,
            }
          );
          const dateDebut = new Date();
          const dateFin = new Date(this.awardDetails.endDate);
          this.creerMinuteur(dateDebut, dateFin);

          this.getCategorieInAward(this.award.name);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.getAmountPaymentInAward();

    this.getAllCandidateInAward();

    this.getAllTransactionInAward();
    // this.process = true

    // function afficherMessage() {
    //     console.log("ok");
    // }

    // Appel de la fonction toutes les 5 secondes (5000 millisecondes)
    setInterval(this.getDataAward(), 5000);

    function getDatesBetween(startDate, endDate) {
      const dates = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    }

    // Exemple d'utilisation :
    const dateDebut = new Date("2023-09-01"); // Date de début
    const dateFin = new Date("2023-09-10"); // Date de fin

    const datesEntreDebutEtFin = getDatesBetween(dateDebut, dateFin);

    // Afficher les dates dans la console
    datesEntreDebutEtFin.forEach((date) => {
      console.log(date.toDateString());
    });
  },

  computed: {
    // Calcul de la liste des articles filtrés en fonction de la recherche
    filteredCandidatList() {
      if (!this.searchQuery) {
        return this.award.categories;
      }
      return this.award.categories.filter((categorie) => {
        const fullName = categorie.categorie.name;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />

    <!-- <div class="card p-2 row">
            <div class="page-title-right font-size-15">
                <span>Compétiton > </span><span>{{ awardDetails.name }}</span>
            </div>
        </div> -->

    <!-- <newHeader :items="items" v-if="title"></newHeader> -->

    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>
    <div style="display: flex; justify-content: space-between">
      <div>
        <h2>{{ time }}</h2>
      </div>
      <div>
        <!-- <router-link :to="`/award/voteListInAward/${this.$route.params.id}/${award.name}`" class="btn c2play-primary m-2">
                    <span style="color: #fff"><i class="mdi mdi-star font-size-18"></i>Liste des votes</span>
                </router-link> -->
        <button
          type="submit"
          @click="confirm"
          class="btn c2play-primary"
          v-if="
            awardDetails.isPublished == null ||
            awardDetails.isPublished == false
          "
        >
          <span style="color: #fff"
            ><i class="mdi mdi-star font-size-18"></i> Publier</span
          >
        </button>
        <button
          type="submit"
          @click="confirmUnPublishAward"
          class="btn c2play-primary"
          v-else
        >
          <span style="color: #fff"
            ><i class="mdi mdi-star font-size-18"></i> Dépublier</span
          >
        </button>
      </div>
    </div>
    <br />
    <div class="card" style="padding: 20px; border-radius: 7px">
      <div class="row">
        <div class="col-xl-5">
          <div class="product-detai-imgs" style="">
            <img
              :src="image"
              alt
              style="
                object-fit: cover;
                height: 320px;
                width: 100%;
                border-radius: 10px;
              "
            />
          </div>
        </div>

        <div class="col-xl-7 col-sm-12 border-1">
          <div class="mt-1 card p-2 shadow-none border">
            <div style="text-align: justify">
              <div
                class="mb-2"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div>
                  <h2>{{ awardDetails.name }}</h2>
                </div>
                <div>
                  <router-link
                    :to="`/award/regulationAward/${this.$route.params.id}/${award.name}`"
                    title="Règlement de la compétition"
                    class="btn border"
                    style="margin-right: 10px"
                  >
                    <span style="color: #07693a"
                      ><i
                        class="mdi mdi-text-box-check-outline font-size-18"
                      ></i
                    ></span>
                  </router-link>
                  <button
                    type="submit"
                    title="Détails de la compétition"
                    class="btn border"
                  >
                    <span style="color: #07693a" @click="showUpdateModal"
                      ><i class="mdi mdi-eye font-size-18"></i
                    ></span>
                  </button>
                </div>
              </div>
              <div>{{ awardDetails.description }}</div>
            </div>
          </div>

          <div class="row">
            <statAward
              :title="'Catégories'"
              :icon="'mdi mdi-shape'"
              :value="award.categories.length"
            >
            </statAward>
            <statAward
              :title="'Nommés'"
              :icon="'mdi mdi-account'"
              :value="allCandidate"
            ></statAward>
            <statAward
              :title="'Votes'"
              :icon="'mdi mdi-hand'"
              :value="voteCountInCategorie"
            ></statAward>
            <statAward
              :route="`/award/transactionInAward/${this.$route.params.id}/${award.name}`"
              class="btn"
              :title="'Transactions'"
              :icon="'mdi mdi-compare-horizontal'"
              :value="transactionCount"
            >
            </statAward>
            <statAward
              :title="'Montant (XAF)'"
              :icon="'mdi mdi-cash-100'"
              :value="amountPayment"
            ></statAward>
          </div>
        </div>

        <!-- <div class="col-xl-7">
                    <div class="mt-1">
                        <div style="text-align: justify;">
                            <div style="display: flex; align-items: center; justify-content: space-between;">
                                <div>
                                    <h2></h2>
                                </div>
                                <div>
                                    <span></span>
                                </div>
                            </div>
                            <br>
                            <h5>Est-elle payante? : <strong>{{ checkTrueOrFalse(awardDetails.free) }}</strong></h5>
                            <h5>Peut-on voter plusieurs fois? : <strong>{{ checkTrueOrFalse(awardDetails.multiVote)
                            }}</strong>
                            </h5>
                            <h5>Peut-on voter la même personne plusieurs fois? : <strong>{{
                                checkTrueOrFalse(awardDetails.multiSameVote) }}</strong></h5><br>
                            <h5><strong></strong></h5>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>

    <div style="display: flex; justify-content: space-between">
      <div>
        <div style="min-width: 300px; margin-right: 10px">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Rechercher une catégorie"
              style="border-radius: 8px"
              v-model="searchQuery"
            />
          </div>
        </div>
      </div>

      <div
        v-if="
          awardDetails.isPublished == null || awardDetails.isPublished == false
        "
      >
        <button
          type="submit"
          @click="showAddModalCandidate"
          class="btn c2play-primary"
          style="margin-right: 10px"
        >
          <span style="color: #fff"
            ><i class="fas fa-plus font-size-18"></i> Ajouter un nommé
          </span>
        </button>
        <button
          type="submit"
          @click="showAddModal"
          class="btn c2play-primary"
          style="margin-right: 10px"
        >
          <span style="color: #fff"
            ><i class="fas fa-plus font-size-18"></i> Ajouter une catégorie
          </span>
        </button>
      </div>
    </div>
    <br />

    <div class="row">
      <awardCategorieCard
        v-for="categorie in filteredCandidatList"
        :key="categorie.id"
        :statutAward="award.isPublish"
        :categorie="categorie"
        :rang="`${award.categories.indexOf(categorie) + 1} sur ${
          award.categories.length
        }`"
      >
      </awardCategorieCard>
    </div>

    <!-- <chartCandidatAwardVue :serie="serie" :title="shartTitle" :categorie="categorieStats"></chartCandidatAwardVue> -->

    <b-modal
      v-model="addModalCandidate"
      id="modal-xl"
      size="xl"
      title="Nouveau candidat"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="row">
            <div class="form-group col-lg-12 mb-3">
              <label for="projectname" class="col-form-label"
                >Nom du candidat</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le nom du candidat"
                  v-model="candidateUser.name"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-lg-6">
              <label for="projectbudget" class="col-form-label"
                >Image Portrait</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imgPortraitCandidate($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectbudget" class="col-form-label"
                >Image plateau (Paysage)</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imgPaysageCandidate($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="candidateUser.description"
                :placeholder="
                  $t('pages.programme.formulaire.description.placeholder')
                "
              ></textarea>
            </div>
          </div>

          <div class="row">
            <label for="projectbudget" class="col-form-label"
              >Sélectionnez les catégories du candidat</label
            >
            <div
              class="col-lg-3"
              v-for="categorie in award.categories"
              :key="categorie.id"
            >
              <b-form-checkbox
                v-model="candidateUser.idCategorie"
                name="checkbox-1"
                :value="categorie.categorie.id"
              >
                <div>
                  {{ categorie.categorie.name }}
                </div>
              </b-form-checkbox>
            </div>
          </div>
        </form>
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              style="position: relative; left: 40%"
              @click="createCandidate"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="addModal"
      id="modal-xl"
      size="xl"
      title="Nouvelle catégorie"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="row">
            <div class="form-group col-lg-12 mb-3">
              <label for="projectname" class="col-form-label">Nom</label>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="awardCategorie.name"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le nom de la catégorie"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Image Portrait</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imageFileSelected($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Image plateau (Paysage)</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imagePFileSelected($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Vidéo de couverture</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="videoSelect($event)"
                  class="form-control"
                  accept=".MKV, .AVI, .MP4, MOV"
                />
              </div>
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                v-model="awardCategorie.description"
                rows="3"
                :placeholder="
                  $t('pages.programme.formulaire.description.placeholder')
                "
              ></textarea>
            </div>
          </div>
        </form>
        <!-- <div class="row mb-4">
              <label class="col-form-label col-lg-2">Vidéo</label>
              <div class="col-lg-10">
                <DropZone @drop.prevent="drop" @change="selectedFile" />
                <span class="file-info">Fichier: {{ dropzoneFile.name }}</span>
              </div>
            </div> -->
        <!-- <h4>{{ tags }}</h4>
            <h4>{{ categories }}</h4> -->
        <div class="row justify-content-center">
          <div
            style="
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <button
              type="submit"
              class="btn c2play-primary"
              @click="createAwardCategorie"
            >
              <span>{{ $t("addButton") }} </span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="updateModal"
      id="modal-xl"
      size="xl"
      title="Détails de la compétition"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>

        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-6">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                :disabled="isDisabled"
                id="projectname"
                name="projectname"
                v-model="award.name"
                type="text"
                class="form-control"
                :placeholder="$t('pages.videos.formulaire.titre.placeholder')"
              />
            </div>
            <div class="form-group col-lg-6 mb-3">
              <label class="col-form-label"
                >Prix de l'accès au vote (XAF)</label
              >
              <div class="col-lg-12">
                <input
                  :disabled="isDisabled"
                  id="projectname"
                  v-model="award.votePrice"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le prix du vote"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label">
                Date de début</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  :disabled="isDisabled"
                  id="projectname"
                  v-model="award.startDate"
                  name="projectname"
                  type="datetime-local"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                />
                <div>{{ award.startDate }}</div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label">Date de fin</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  :disabled="isDisabled"
                  id="projectname"
                  v-model="award.endDate"
                  name="projectname"
                  type="datetime-local"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                />
                <div>{{ award.endDate }}</div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >{{ $t("pages.videos.formulaire.files.image") }} portrait</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  :disabled="isDisabled"
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelect($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Image paysage</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  :disabled="isDisabled"
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelectPaysage($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label">
                Est-elle payante</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      :disabled="isDisabled"
                      v-model="award.isFree"
                      name="some-radios1"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      :disabled="isDisabled"
                      v-model="award.isFree"
                      name="some-radios1"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >A-t-elle des categories</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      :disabled="isDisabled"
                      v-model="award.hasCategorie"
                      name="some-radios2"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      :disabled="isDisabled"
                      v-model="award.hasCategorie"
                      name="some-radios2"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Peut-on voter pour plusieurs candidats</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      :disabled="isDisabled"
                      v-model="award.isMultiVote"
                      name="some-radios3"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      :disabled="isDisabled"
                      v-model="award.isMultiVote"
                      name="some-radios3"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Peut-on voter le même candidat plusieurs fois</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      :disabled="isDisabled"
                      v-model="award.isMultiSameVote"
                      name="some-radios4"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      :disabled="isDisabled"
                      v-model="award.isMultiSameVote"
                      name="some-radios4"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-2">
            <label for="projectdesc" class="col-form-label">{{
              $t("pages.videos.formulaire.description.label")
            }}</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                :disabled="isDisabled"
                v-model="award.description"
                class="form-control"
                rows="3"
                :placeholder="
                  $t('pages.videos.formulaire.description.placeholder')
                "
              ></textarea>
            </div>
          </div>
        </form>

        <div class="row">
          <div
            class="col-lg-12"
            style="margin-top: 10px"
            v-if="isDisabled == false"
          >
            <button
              type="submit"
              class="btn c2play-primary"
              @click="updateAward"
              style="position: relative; left: 45%"
            >
              <span style="color: #fff"
                ><i class="mdi mdi-pencil font-size-18"></i> Modifier
              </span>
            </button>
          </div>
          <div v-else class="col-lg-12" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              @click="toggleInput"
              style="position: relative; left: 45%"
            >
              Activer la modification
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="showConfirmNameAward"
      hide-footer
      centered
      header-class="border-0"
    >
      <div class="text-center mb-4">
        <div class="row justify-content-center">
          <div class="col-xl-10">
            <div class="mb-3 row">
              <label class="col-form-label"
                >Saisissez le nom complet de la compétition</label
              >
              <div class="col-md-12">
                <div class="form-group mb-3">
                  <div class="col-lg-12">
                    <input
                      id="projectname"
                      v-model="confirmNameAward"
                      name="projectname"
                      type="text"
                      class="form-control"
                      placeholder="Entrez le nom de la compétition"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary col-md-12"
              type="button"
              id="button-addon2"
              @click="confirmUnpublishNameAward"
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
