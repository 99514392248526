<script>

/**
 * Stat component -- specify the widget icon, title and value.
 */
export default {
    props: {
        title: {
            default: ""
        },
        value: {
            default: ""
        },
        icon: {
            default: ""
        },
        route: {
            default: ""
        },
    },
    methods: {
        formatNumberWithSpaces(number) {
            // Convertit le nombre en chaîne de caractères
            const numberString = number.toString();

            // Utilise une expression régulière pour ajouter un espace après chaque groupe de trois chiffres
            const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

            return formattedNumber;
        }
    },
};
</script>

<template>
    <div class="col-sm-4" style="text-align:center;">
        <RouterLink :to="route" class="text-muted">
            <div><span style="color: #07693A"><i :class="`${icon} font-size-30`"></i></span></div>
            <div>{{ title }}</div>
            <div>
                <b-spinner v-if="value == null" variant="success" role="status"></b-spinner>
                <strong v-else>{{ formatNumberWithSpaces(value) }}</strong>
            </div>
        </RouterLink>
    </div>
</template>